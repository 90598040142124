import { RichText } from "prismic-reactjs"
import * as queryString from "query-string"
import * as React from "react"
import { useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import { quizSerializer } from "../app/utilities/quizSerializer"
import Layout from "../components/layout"

import {
  Button,
  Label,
  RadioFields,
  TextField,
} from "../salesforce/FormElements"
require("../2020/index.scss")

export const query = graphql`
  query QatarQuery($lang: String!) {
    prismic {
      allWk2022s(lang: $lang) {
        edges {
          node {
            user_played
            success_message_cta
            success_message
            not_eligible_message
            redeem_coupon_button
            submit_quiz_button
            no_active_quiz_message
            login_message
            failure_message
            answer_1
            answer_2
            answer_3
            sifting_question
            correct_answer
            question
          }
        }
      }
    }
  }
`
const WK2022 = props => {
  const { pageContext, location, data } = props
  const { i18n, t } = useTranslation("quiz")
  if (i18n.language !== pageContext.lang && pageContext.lang) {
    i18n.changeLanguage(pageContext.lang)
  }

  const { uid, url } = queryString.parse(location.search)
  let node = data.prismic.allWk2022s.edges[0].node
  let question = node.question

  let questions = { question }

  questions["answers"] = [
    { value: "answer_1", label: node.answer_1 },
    { value: "answer_2", label: node.answer_2 },
    { value: "answer_3", label: node.answer_3 },
  ]

  const [isLoading, setIsLoading] = React.useState(true)
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  const [isEligible, setIsEligible] = React.useState(false)
  const [hasPlayed, setPlayed] = React.useState(false)
  const [formData, setFormData] = React.useState(null)
  const [isFailure, setIsFailure] = React.useState(null)
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  useEffect(() => {
    if (uid) {
      setIsLoggedIn(true)
    }
  }, [uid])

  useEffect(() => {
    fetch(`/api/superfan?uid=${uid}`)
      .then(res => res.json())
      .then(res => {
        if (!res.error) {
          setIsEligible(true)
          setFormData({ uid })
        }
        if (res.error) {
          if (res.message === "USER_PARTICIPATED") {
            setPlayed(true)
            setIsEligible(true)
          }
          if (res.message === "USER_NOT_FOUND") {
            setPlayed(false)
            setIsEligible(false)
          }
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.error("Error:", error)
      })
  }, [])

  const onSubmit = React.useCallback(
    evt => {
      evt.preventDefault()

      if (isSuccess || isFailure || isSubmitting) {
        return false
      }

      try {
        setIsSubmitting(true)
        async function postForm() {
          const requestOptions = {
            method: "POST",
            "Content-Type": "application/json",
            body: JSON.stringify({
              uid,
              answer: evt.target["answer"].value,
              sifting: evt.target["sifting"].value,
            }),
            redirect: "follow",
            mode: "cors",
          }
          try {
            const response = await fetch(`/api/qatar`, requestOptions)
            const data = await response.json()
            setIsSubmitting(false)
            if (data && data.success === true) {
              setIsSuccess(true)
            }
            if (data && data.error === true) {
              setIsFailure(true)
            }
          } catch (err) {
            console.error(err)
            setIsSubmitting(false)
          }
        }
        postForm()
      } catch (error) {
        setIsSubmitting(false)
      }
    },
    [isSubmitting, isSuccess, isFailure, formData]
  )

  if (!isLoggedIn && url) {
    return (
      <>
        <div className="o-form">
          <RichText
            render={node.login_message}
            htmlSerializer={quizSerializer}
          />
          <a href={`${url}`} className="o-button" target="_parent">
            <Trans i18nKey="refresh" ns="quiz" />
          </a>
        </div>
      </>
    )
  }

  if (isLoading) {
    return (
      <>
        <div className="o-form">Loading ...</div>
      </>
    )
  }

  if (!question) {
    return (
      <>
        <div className="o-form">
          <RichText
            render={node.no_active_quiz_message}
            htmlSerializer={quizSerializer}
          />
        </div>
      </>
    )
  }

  if (!isEligible) {
    return (
      <>
        <div className="o-form">
          <RichText
            render={node.not_eligible_message}
            htmlSerializer={quizSerializer}
          />
        </div>
      </>
    )
  }

  if (hasPlayed) {
    return (
      <>
        <div className="o-form">
          <RichText render={node.user_played} htmlSerializer={quizSerializer} />
        </div>
      </>
    )
  }

  if (isSuccess) {
    return (
      <>
        <div className="o-form">
          <RichText
            render={node.success_message}
            htmlSerializer={quizSerializer}
          />
        </div>
      </>
    )
  }

  if (isFailure) {
    return (
      <>
        <div className="o-form">
          <RichText
            render={node.failure_message}
            htmlSerializer={quizSerializer}
          />
        </div>
      </>
    )
  }

  return (
    <Layout>
      <form
        name="quiz"
        method={"post"}
        onSubmit={onSubmit}
        className={"o-form o-form--reset"}
      >
        {questions && (
          <>
            <Label
              text={questions.question}
              required={true}
              title={t("required")}
            >
              <RadioFields
                name="answer"
                values={questions.answers}
                first=""
                required={true}
              />
            </Label>

            <Label
              text={node.sifting_question}
              required={true}
              title={t("required")}
            >
              <TextField
                type="number"
                name="sifting"
                required={true}
                max-length="50"
              />
            </Label>

            <Button name={node?.submit_quiz_button || "Submit"} />
          </>
        )}
      </form>
    </Layout>
  )
}

export default WK2022
